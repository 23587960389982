import { nextPage } from '__test__/mock-view';

import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';

import { PARAM_ORIGIN, ROUTES } from '@/constants';
import { NAVIGATION_FLOW } from '@/constants/navigation-flow';

import { getRouteWithOrigin } from '@/utils';

export type NavigationParams = PARAM_ORIGIN | { [x: string]: string | number } | null;

export function getParams(data: { [x: string]: string | number }) {
  return Object.keys(data)
    .map((key) => `${key}=${encodeURIComponent(data[key])}`)
    .join('&');
}

export const navigateBasedOnCondition = (
  navigateFn: (routes: ROUTES, params: NavigationParams) => void,
  value: boolean,
  routeTrue: ROUTES,
  routeFalse: ROUTES,
  options?: Parameters<typeof nextPage>[1]
) => {
  const routeToNavigationhasFlow = value ? routeTrue : routeFalse;
  navigateFn(routeToNavigationhasFlow, options);
};

export const useNavigationFlow = () => {
  const { push, query } = useRouter();
  const pathname = usePathname();

  const navigation = NAVIGATION_FLOW[pathname as ROUTES];

  const nextPage = (route: ROUTES, params?: NavigationParams) => {
    //this is necessary cause router can't be used in server side
    if (typeof window === 'undefined') return null;

    if (navigation.next.includes(route)) {
      if (!params) {
        push(route, undefined);
      } else if (typeof params === 'object') {
        const paramsUrl = getParams(params);
        push(`${route}?${paramsUrl}`);
      } else push(getRouteWithOrigin(route, params as PARAM_ORIGIN));
    } else console.error('Invalid route', route);
  };

  const nextPageOnCondition = (
    value: boolean,
    routeTrue: ROUTES,
    routeFalse: ROUTES,
    options?: Parameters<typeof nextPage>[1]
  ) => {
    navigateBasedOnCondition(nextPage, value, routeTrue, routeFalse, options);
  };

  const previousPage = (route: ROUTES, params?: PARAM_ORIGIN | { [x: string]: string | number } | null) => {
    if (navigation.previous.includes(route)) {
      if (!params) push(route, undefined);
      else if (typeof params === 'object') {
        const paramsUrl = getParams(params);
        push(`${route}?${paramsUrl}`);
      } else push(getRouteWithOrigin(route, params));
    } else {
      console.error('Invalid route', route);
    }
  };

  const previousPageOnCondition = (
    value: boolean,
    routeTrue: ROUTES,
    routeFalse: ROUTES,
    options?: Parameters<typeof nextPage>[1]
  ) => {
    navigateBasedOnCondition(previousPage, value, routeTrue, routeFalse, options);
  };

  const originParam = query?.origin ? { origin: query.origin as string } : null;

  return {
    nextPage,
    nextPageOnCondition,
    previousPage,
    previousPageOnCondition,
    query,
    originParam,
  };
};
